.about {
    &__content {
        display: flex;
        gap: 40px;
        padding: 20px;
        width: 100%;

        &__personalWrapper, &__servicesWrapper {
            width: 50%;
        }

        &__personalWrapper {
            &__headerText {
                color: var(--yellow-theme-sub-text-color);
                display: inline-block;
                font-size: 2.5rem;
                font-weight: 600;
                line-height: 1em;
                letter-spacing: 0.5px;
                position: relative;
                padding: 5px 10px;
                border-bottom: 2px solid var(--yellow-theme-main-color);
                margin-bottom: 20px;
            }

            &__headerText::before, &__headerText::after {
                position: absolute;
                content: '';
                width: 2px;
                height: 5px;
                background-color: var(--yellow-theme-main-color);
                bottom: -1px;
            }
    
            &__headerText::before {
                left: 0;
            }
    
            &__headerText::after {
                right: 0;
            }

            #personalInformationHeaderText {
                padding-top: 20px;
            }

            p {
                color: var(--yellow-theme-sub-text-color);
                font-size: 1.8rem;
                line-height: 1.65;
                padding-bottom: 20px;
                border-bottom: 1px dashed var(--yellow-theme-main-color);
            }

            ul > li {
                list-style: none;
                line-height: 1rem;
                margin-bottom: 15px;

                span.title, span.value {
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    border-bottom: 2px solid var(--yellow-theme-main-color);
                    position: relative;
                    display: inline-block;
                    margin-right: 16px;
                    padding-bottom: 5px;
                    color: var(--yellow-theme-sub-text-color);
                }

                span.value {
                    border-bottom: none;
                }

            }
        }

        &__servicesWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 100px;
            

            &__innerContent {
                width: 350px;
                height: 350px;
                background: radial-gradient(180px, var(--yellow-theme-main-color), transparent 90%);
                position: relative;
                border-radius: 50%;
                border: 1.5px solid var(--yellow-theme-main-color);
                animation: rotation 10s linear 1s infinite;

                div:nth-child(1) {
                    position: absolute;
                    top: -9%;
                    left: 41%;
                }

                div:nth-child(2) {
                    position: absolute;
                    right: -9%;
                    top: 41%;
                }

                div:nth-child(3) {
                    position: absolute;
                    bottom: -9%;
                    left: 41%;
                }

                div:nth-child(4) {
                    position: absolute;
                    left: -9%;
                    top: 41%;
                }
            }
        }
    }

    @keyframes rotation {
        100% {
            transform: rotate(360deg);
        }
    }
}



@media only screen and (max-width: 768px) {
    .about__content {
        flex-direction: column;

        &__personalWrapper, &__servicesWrapper {
            width: 100%;
        }

        &__servicesWrapper {
            margin: 10px 0px;
            &__innerContent {
                width: 300px;
                height: 300px;
                background: radial-gradient(120px, var(--yellow-theme-main-color), transparent 120%);

                div:nth-child(1) {
                    position: absolute;
                    top: -10%;
                    left: 40%;
                }

                div:nth-child(2) {
                    position: absolute;
                    right: -10%;
                    top: 40%;
                }

                div:nth-child(3) {
                    position: absolute;
                    bottom: -10%;
                    left: 40%;
                }

                div:nth-child(4) {
                    position: absolute;
                    left: -10%;
                    top: 40%;
                }
            }
        }
    }
}