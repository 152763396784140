.contact {
    &__content {
        padding: 20px;

        &__headerText {
            color: var(--yellow-theme-sub-text-color);
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: 0.5px;
            position: relative;
            text-transform: uppercase;
            padding: 5px 10px;
            border-bottom: 2px solid var(--yellow-theme-main-color);
        }

        &__headerText::before, &__headerText::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 5px;
            background-color: var(--yellow-theme-main-color);
            bottom: -1px;
        }

        &__headerText::before {
            left: 0;
        }

        &__headerText::after {
            right: 0;
        }

        &__form {
            margin-top: 40px;

            &__controlsWrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                margin-bottom: 30px;

                div {
                    position: relative;
                    .inputName, .inputEmail, .inputDescription {
                        width: 100%;
                        border: 2px solid var(--yellow-theme-main-color);
                        padding: 16px 12px;
                        transition: all 0.5s ease;
                        position: relative;
                        background-color: transparent;
                        color: var(--yellow-theme-sub-text-color);
                    }

                    .nameLabel, .emailLabel, .descriptionLabel {
                        font-weight: 500;
                        position: absolute;
                        top: 7px;
                        left: 12px;
                        transition: all 0.2s ease;
                        color: var(--yellow-theme-sub-text-color);
                        font-size: 16px;
                    }

                    .inputName:focus, .inputEmail:focus, .inputDescription:focus {
                        outline: none;
                    }

                    .inputName:focus + .nameLabel, .inputName:valid + .nameLabel,
                    .inputEmail:focus + .emailLabel, .inputEmail:valid + .emailLabel,
                    .inputDescription:focus + .descriptionLabel, .inputDescription:valid + .descriptionLabel
                    {
                        font-size: 18px;
                        top: -25px;
                        left: 0px;
                    }
                }
            }
        }

        button {
            cursor: pointer;
            padding: 10px 70px;
            font-size: 2rem;
            background: transparent;
            color: var(--yellow-theme-main-color);
            border: 1.5px solid var(--yellow-theme-main-color);
            border-radius: 4px;
            font-weight: 500;

            &:hover {
                color: #000000;
                background: var(--yellow-theme-main-color);
            }
        }

    }
}

@media only screen and (max-width: 768px) {
    .contact__content__form {
        text-align: center;

        &__controlsWrapper {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}