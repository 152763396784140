.skills {
    
    &__contentWrapper {
        display: flex;
        padding: 20px;
        gap: 20px;

        &__innerContent {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__categoryText {
                color: var(--yellow-theme-sub-text-color);
                display: inline-block;
                font-size: 22px;
                font-weight: 600;
                line-height: 1em;
                letter-spacing: 0.5px;
                position: relative;
                text-transform: uppercase;
                padding: 5px 10px;
                border-bottom: 2px solid var(--yellow-theme-main-color);
            }

            &__categoryText::before, &__categoryText::after {
                position: absolute;
                content: '';
                width: 2px;
                height: 5px;
                background-color: var(--yellow-theme-main-color);
                bottom: -1px;
            }

            &__categoryText::before {
                left: 0;
            }

            &__categoryText::after {
                right: 0;
            }

            div {
                width: 100%;
            }

            .progressbarWrapper {
                padding: 50px 0px 0px 0px;
                

                p {
                    font-size: 1.6rem;
                    margin: 0 0 10px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: var(--yellow-theme-sub-text-color);
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .skills {
        &__contentWrapper {
            flex-direction: column;
        }
    }

    .skills__contentWrapper__innerContent__progressbarContainer {
        margin-bottom: 20px;
    }
}