.resume {
    height: 100%;
}

.timeline {
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: center;

    &__education {
        width: 100%;
       // gap: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__headerText {
            color: var(--yellow-theme-sub-text-color);
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: 0.5px;
            position: relative;
            text-transform: uppercase;
            padding: 5px 10px;
            border-bottom: 2px solid var(--yellow-theme-main-color);
            margin-bottom: 20px;
        }

        &__headerText::before, &__headerText::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 5px;
            background-color: var(--yellow-theme-main-color);
            bottom: -1px;
        }

        &__headerText::before {
            left: 0;
        }

        &__headerText::after {
            right: 0;
        }

        &__verticalTimelineElement {
            padding: 0px 0px 30px 0px;

            &:last-child {
                padding-bottom: 0px;
            }
        }

        .verticalTimelineElementTitleWrapper {
            display: flex;
            align-items: center;

            h3,h4 {
                font-size: 16px;
            }
        }

        .verticalTimelineElementTitleWrapperDescription {
            font-size: 18px;
        }

        .vertical-timeline-element-content {
            box-shadow: none;
        }

        .vertical-timeline-element-content-arrow {
            display: none;
        }

        .vertical-timeline-element-date {
            opacity: 1;
            color: var(--yellow-theme-sub-text-color);
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .timeline {
        flex-direction: column;

        &__experience, &__education {
            width: 100%;
            gap: 2rem;
        }

        &__education {
            margin-top: 2rem;
        }
    }
}